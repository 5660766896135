
import { defineComponent, toRefs, ref, computed, reactive, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import FormItem from '@/components/formItem/index.vue'
import { PeerClass, RegPeerClass } from '@/model/competitiveAnalysis/peerData/peerData'
import editButton from '@/components/editButton/index.vue'
import accompanyApi from '@/axios/api/accompany'
import { message } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import yTable from '@/components/yTable/index.vue'
import { deleteModal } from '@/utils/deleteModal'
import { FormItemListType } from '@/types/comm'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  components: {
    listLayout,
    FormItem,
    editButton,
    yTable,
    Drawer,
  },
  setup() {
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }

    const peerTable = ref()
    //获取路由
    let router = useRouter()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new RegPeerClass(),
      dataSource: [] as PeerClass[],
      columns: [
        {
          title: '同行名称',
          dataIndex: 'competitorName',
          key: 'competitorName',
          width: 150,
          resizable: true,
        },
        {
          title: '工商号',
          dataIndex: 'companyCode',
          key: 'companyCode',
          width: 120,
          resizable: true,
        },
        {
          title: '简称',
          dataIndex: 'shortName',
          key: 'shortName',
          width: 120,
          resizable: true,
        },
        {
          title: '所属区域',
          dataIndex: 'area',
          key: 'area',
          width: 120,
          resizable: true,
        },
        {
          title: '启用状态',
          dataIndex: 'isEnable',
          key: 'isEnable',
          width: 100,
          resizable: true,
        },
        {
          title: '创建人',
          dataIndex: 'createName',
          key: 'createName',
          width: 100,
          resizable: true,
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          width: 100,
          resizable: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 100,
          resizable: true,
        },
      ] as ColumnsTypeData[],
      selectedRowKeys: [] as string[],
      loading: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'competitorName',
          placeholder: '请输入同行名称',
          label: '同行名称',
        },
        {
          type: 'subInput',
          prop: 'companyCode',
          placeholder: '请输入工商号',
          label: '工商号',
        },
        {
          type: 'subSelect',
          prop: 'isEnable',
          placeholder: '请选择是否启用',
          label: '是否启用:',
          optionList: [
            {
              value: 0,
              label: '未启用',
            },
            {
              value: 1,
              label: '启用',
            },
          ],
        },
      ] as FormItemListType<PeerClass>,
    })
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      accompanyApi
        .AccompanyList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectList = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectList()
    //新增按钮
    const addData = () => {
      router.push('/competitiveAnalysis/peerData/addPeerData')
    }
    //编辑按钮
    const updateData = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择一项数据！')
        return
      }
      router.push(`/competitiveAnalysis/peerData/updatePeerData/${pageData.selectedRowKeys[0]}`)
    }
    //删除按钮
    const delData = () => {
      if (pageData.selectedRowKeys.length === 0) {
        message.warning('至少选中一条')
        return
      }
      let data = pageData.selectedRowKeys.map((item) => {
        return {
          id: item,
        }
      })
      const ok = () => {
        accompanyApi
          .AccompanyDel(data)
          .then(() => {
            message.success('删除成功')
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }
    //跳转至账号设置页面
    const accountSet = (id: string, name: string) => {
      console.log(`跳转至id为${id}页面`)
      router.push({
        path: `/competitiveAnalysis/peerData/accountInformation/${id}`,
        query: { name },
      })
    }
    //搜索
    const search = () => {
      console.log(pageData.selectFrom)
    }
    //
    const reset = () => {
      pageData.selectFrom.data = new PeerClass()
      selectList()
    }
    //打开关联
    const associated = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选中一条')
        return
      }
      // let accompanyId = (
      //   pageData.dataSource.find((item) => item.id === pageData.selectedRowKeys[0]) as PeerClass
      // ).companyCode as string
      router.push(
        '/competitiveAnalysis/peerData/associatedPeerInformation/' + pageData.selectedRowKeys[0]
      )
    }
    return {
      // ...toRefs(pageData),
      addData,
      ...toRefs(pageData),
      peerTable,
      accountSet,
      updateData,
      search,
      fallbackImg: require('@/assets/img/fallbackImg.svg'),
      selectList,
      delData,
      reset,
      buttonObj,
      currentConfigure,
      associated,
      updateColumns,
    }
  },
})
