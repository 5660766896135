/**
 * 同行信息实体类和接口
 */
import { ReqPage } from '@/model/common/reqPage'
//地址接口定义
export interface Area {
  country: string | undefined
  province: string | undefined
  city: string | undefined
  district: string | undefined
}
//同行信息类
export class PeerClass {
  id: string | undefined // "string //主键"
  companyCode: string | undefined // 工商号"
  country: string | undefined //国家
  province: string | undefined //省份
  city: string | undefined //市
  district: string | undefined //区县
  competitorName: string | undefined //名称
  shortName: string | undefined //简称
  isEnable: number | undefined //是否启用;1：启用 0：禁用
  createdBy: string | undefined //创建人
  createName: string | undefined //创建姓名
  createdTime: string | undefined //创建时间
  updatedBy: string | undefined //更新人
  updatedTime: string | undefined //更新时间
  urlIndex: string | undefined //首页地址
  area: string | undefined //地区
  constructor() {
    this.id = undefined
    this.companyCode = undefined
    this.country = undefined
    this.province = undefined
    this.isEnable = undefined
    this.district = undefined
    this.isEnable = undefined
    this.city = undefined
    this.shortName = undefined
    this.createdBy = undefined
    this.updatedBy = undefined
    this.urlIndex = undefined
    this.competitorName = undefined
    this.updatedTime = undefined
    this.createdTime = undefined
    this.area = undefined
    this.createName = undefined
  }
}
//分页查询类
export class RegPeerClass extends ReqPage {
  data: PeerClass
  constructor() {
    super()
    this.data = new PeerClass()
  }
}
export class ResPeerClass extends ReqPage {
  data: PeerClass[]
  constructor() {
    super()
    this.data = []
  }
}
//账号信息类
export class AccountClass {
  id: string | undefined
  account: string | undefined //账号
  password: string | undefined //密码
  alias: string | undefined //别名
  isEnable: number | undefined //启用状态
  describe: string | undefined //描述
  creatUser: string | undefined //创建人
  creatTime: string | undefined //创建时间
  constructor() {
    this.id = undefined
    this.account = undefined
    this.password = undefined
    this.alias = undefined
    this.isEnable = undefined
    this.describe = undefined
    this.creatUser = undefined
    this.creatTime = undefined
  }
}
//总物料类别
export interface Category {
  categoryNo: string //物料类别编号'
  categoryName: string //物料类别名称'
}
export interface ReqCategory extends ReqPage {
  data: Category
}
export interface ResCategory extends ReqPage {
  data: Category[]
}
//总物料类别
export interface LinkedCategory {
  id?: string //主键
  productCategory?: string //物料类别
  productCategoryName?: string //类别名称
  accompanyId?: string //同行id【必须】
  createdBy?: string //创建人
  createdTime?: string //创建时间
  updatedBy?: string //更新人
  updatedTime?: string //更新时间
  isDelete?: string //是否删除 0：未删除 1：已删除
  deleteTime?: string //删除时间
}
export interface ReqLinkedCategory extends ReqPage {
  data: LinkedCategory
}
export interface ResLinkedCategory extends ReqPage {
  data: LinkedCategory[]
}
